const whiteListed = ['/combat', '/event', '/prodigy', '/pitch-assist'];

const checkWhiteListed = (router) => {
  const redirectUrl = router.query.redirect;
  if (!redirectUrl) return null;
  for (let i = 0; i < whiteListed.length; i += 1) {
    if (redirectUrl.startsWith(whiteListed[i])) return redirectUrl;
  }
  return null;
};

export default checkWhiteListed;
