import styled from '@emotion/styled';
import checkWhiteListed from '@utils/whitelistRedirectUrls';
import { mediaQuery } from '@styles/index';
import { useRouter } from 'next/router';
import { useState, useEffect } from 'react';
import Layout from '@layout/index';
import validatePresetRedirectURL from '@utils/validateRedirectUrl';
import PageReduxProvider from '@cont/App/PageReduxProviderWrapper';
import Dynamic from '@base/Dynamic/Dynamic';
import useMyInfo from '@api/hooks/auth/useMyInfo';
import pushRoute from '@utils/pushRoute';
import { getIsLoggedIn } from '@api/selectors/auth';
import { NextSeo } from 'next-seo';
import Head from 'next/head';

const Login = Dynamic(() => import('@cont/Login/Login'));

const Container = styled.div`
  margin: auto;
  background: white;
  min-width: 480px;
  grid-column: span 24 / auto;
  padding: 40px;
  border-radius: 16px;
  border: 1px solid var(--color-divider);

  ${mediaQuery.uptoMobile} {
    min-width: 320px;
    max-width: 480px;
    padding: 20px;
    border-radius: 12px;
    grid-column: 1/11;
    margin: 24px 0 0;
  }
`;

const LoginFlow = () => {
  const { query = {}, pathname } = useRouter();
  const { source, redirectTo, redirect_to, is_iframe_login, redirect } = query;
  const isIframeLoginType = is_iframe_login === 'true';
  const redirectUrl = checkWhiteListed({ pathname, query });
  const [backButton, setBackButton] = useState({
    value: false,
    fn: () => {}
  });
  const isLoggedIn = useMyInfo(getIsLoggedIn);
  const title = 'Login | Unacademy';
  const canonicalUrl = 'https://unacademy.com/login';

  useEffect(() => {
    if (!isIframeLoginType) {
      if (isLoggedIn)
        pushRoute(validatePresetRedirectURL(redirectTo) || redirectUrl || '/');
    }
    if (redirect && !redirectUrl) pushRoute('/login', '/login');
  }, [isIframeLoginType, isLoggedIn, redirectTo, redirectUrl, redirect]);

  return (
    <Container>
      <NextSeo title={title} openGraph={{ title }} />
      <Head>
        <title>{title}</title>
        <link rel="canonical" href={canonicalUrl} />
      </Head>
      <Login
        setBackButton={setBackButton}
        backButton={backButton}
        source={source || 'page'}
        presetRedirectURL={redirectTo || redirect_to}
      />
    </Container>
  );
};

LoginFlow.pageInfo = {
  name: 'Login or Sign Up',
  isMWebSupported: true,
  layoutOptions: {
    hideIntercom: true,
    hideFreshbot: true
  }
};

export default LoginFlow;
LoginFlow.Layout = Layout;
LoginFlow.Wrapper = PageReduxProvider;
